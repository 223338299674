module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.png","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/mstile-70x70.png","sizes":"70x70","type":"image/png"},{"src":"/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/mstile-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/mstile-310x150.png","sizes":"310x150","type":"image/png"},{"src":"/mstile-144x144.png","sizes":"144x144","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05095ecd1d802947bd95952743b3f7eb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
