import PiwikPro, { PageViews } from '@piwikpro/react-piwik-pro'

import '@docsearch/css'

import 'fontawesome-4.7/css/font-awesome.min.css'
import './src/styles/global.css'

export const onClientEntry = () => {
  PiwikPro.initialize(`${process.env.GATSBY_PIWIK_TOKEN}`, 'https://beebole.containers.piwik.pro')
}

export const onRouteUpdate = ({ location }) => {
  PageViews.trackPageView(location)
}
